<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M21.9282 11.4254L13.7436 19.2203L10.0718 15.7331C9.57949 15.2613 8.80001 15.2818 8.32821 15.7741C7.85642 16.2664 7.87693 17.0459 8.36924 17.5177L12.8821 21.8254C13.1282 22.051 13.4359 22.1741 13.7231 22.1741C14.0308 22.1741 14.3385 22.051 14.5641 21.8254L23.5897 13.21C24.0821 12.7382 24.1026 11.9587 23.6308 11.4664C23.2 10.9741 22.4205 10.9536 21.9282 11.4254Z"
        fill="inherit"/>
    <path
        d="M16 0C7.17949 0 0 7.17949 0 16C0 24.8205 7.17949 32 16 32C24.8205 32 32 24.8205 32 16C32 7.17949 24.8205 0 16 0ZM16 29.5385C8.53333 29.5385 2.46154 23.4667 2.46154 16C2.46154 8.53333 8.53333 2.46154 16 2.46154C23.4667 2.46154 29.5385 8.53333 29.5385 16C29.5385 23.4667 23.4667 29.5385 16 29.5385Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-check-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
